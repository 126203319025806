.dashboard-container {
  background-color: #F8F9FA;
  font-family: 'Roboto', sans-serif;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #4B0082;
}

.card.metric-card {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card.metric-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.header-section {
  background-color: #E6E6FA;
  padding: 1.5rem;
  border-radius: 0.75rem;
}

button {
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #4B0082;
  color: #fff;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
    text-align: center;
  }

  .header-section {
    text-align: center;
  }

  .header-section img {
    margin-top: 1rem;
  }
}
